// css for form


.btn-add-new {
    text-decoration: none;
    color: #ffffff;
    font-size: 16px;
    background-color: #0052cc;
    font-weight: 400;
    border: 1px solid #0052cc;
    padding: 8px 14px 8px 14px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}


form {

    padding: 10px;

    .add-new-user-form-default {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .formInput {
            margin-bottom: 15px;
            box-sizing: border-box;
            flex-basis: 65%;
            color: #000;

            label {
                display: flex;
                align-items: center;
                gap: 10px;
                color: #172b4d;
                margin-bottom: 6px;
            }

            p {
                margin: unset;
            }

            input {
                min-width: 100%;
                padding: 10px 10px;
                border-radius: 4px;
                border: none;
                border: 1px solid #42526e;
                box-sizing: border-box;
                background-color: #fff;
            }

            textarea {
                min-width: 100%;
                padding: 10px 10px;
                border-radius: 4px;
                border: none;
                border: 1px solid #42526e;
                box-sizing: border-box;
                background-color: #fff;
            }
        }

        .formInputDescription {
            margin-bottom: 15px;
            box-sizing: border-box;
            flex-basis: 100%;
            color: #000;

            label {
                display: flex;
                align-items: center;
                gap: 10px;
                color: #172b4d;
                margin-bottom: 6px;
            }

            textarea {
                min-width: 100%;
                padding: 10px 10px;
                border-radius: 4px;
                border: none;
                border: 1px solid #42526e;
                box-sizing: border-box;
                background-color: #fff;
            }
        }

    }

    .btn-submit {
        width: 150px;
        height: 40px;
        padding: 10px 20px;
        display: block;
        text-align: center;
        border: none;
        background-color: #0052cc;
        color: white;
        border-radius: 4px;
        font-weight: bold;
        cursor: pointer;
        margin-top: 10px;
        text-decoration: none;
    }
}

//Error Message
.errorMessage {
    float: end;
    margin: 3px;
    font-size: 13px;

    p {
        color: #e33417;
        text-transform: capitalize;
    }
}

.candidate-screening-modal-box {
    .MuiDialog-container {
        height: auto !important;
    }
}

.screening-comment-section-div {
    margin-bottom: 12px;

    .screening-comment-sec1 {
        font-size: 16px, ;
        line-break: anywhere;
        color: #2d2c2c;
        margin-bottom: 5px;
    }

    .icon-screening-comment {
        display: flex;
        -ms-flex-align: start;
        align-items: start;
        margin-bottom: 2px;

        .icon-comment {
            font-size: 17px;
            margin-right: 5px;
            color: #9e9c9a;
        }

        span {
            text-decoration: none;
            color: #686a6d;
        }
    }
}